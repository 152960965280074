import React, {useEffect, useState, JSX} from 'react';
import {CardContent, CircularProgress, Grid, Input, InputAdornment, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {matchSorter} from 'match-sorter'
import FilterListIcon from '@mui/icons-material/FilterList';
import AccessControl from '../../RoleBasedAccessControl';
import permissions from '../../RoleBasedAccessControl/permissions';
import Unauthorized from '../../RoleBasedAccessControl/Unauthorized';
import {StyledMainContentCard} from '../../Common/StyledComponents';
import {alertsTableStyles} from '../../Common/MaterialUIStyles';
import {CustomCardHeader} from '../../Common/CustomCardHeader';
import {AlertRow} from './AlertRow';
import routes from '../../../routes';
import {generateClient} from 'aws-amplify/api';
import {Queries} from '../../../bravo-api-service';
import {useDispatch, useSelector} from 'react-redux';
import {setDashboardAlerts, setLookerFolders} from '../../../actions';
import {LookerFolder, LookerFoldersState, SystemState} from '../../../reducers';
import {ListLookerFoldersQuery} from '../../../bravo-api-service/generated/API.service';
import './table.css';
import {listAllFromDynamo} from "../../../utils";

export const DashboardAlerts = (): JSX.Element => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const tableStyles = alertsTableStyles();

    const lookerFolders = useSelector((state: LookerFoldersState ) => state.lookerFolders?.lookerFolders) || null;
    const dashboardAlerts = useSelector((state: SystemState) => state.dashboardAlerts?.dashboardAlerts) || null;
    const [searchString, setSearchString] = useState<string>('');

    useEffect(() => {
        const client = generateClient();
        const fetchLookerFoldersAndAlerts = async () => await Promise.all([
            await client.graphql({query:Queries.listLookerFolders}) as {
                data: ListLookerFoldersQuery
            },
        ]);

        fetchLookerFoldersAndAlerts()
            .then(response => {
                response.forEach(list => {
                    const { data } = list;
                    if ('listLookerFolders' in data) {
                        dispatch(setLookerFolders(data.listLookerFolders));
                    }
                })
            })
            .catch((error: any) => console.error(error));
    }, [dispatch]);

    useEffect(() =>{
        listAllFromDynamo({query: Queries.listDashboardAlerts}, 'listDashboardAlerts').then(
            items => {
                let alertsArray = items === null || false ? [] : items;
                let alerts = Object.assign({}, ...alertsArray.map((x: any) => ({[(x.lookerFolderId)]: {active: x.active, alertText: x.alert}})));
                dispatch((setDashboardAlerts(alerts)))
            }
        );

    }, [dispatch]);

    const updateSearchString = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.target.value);
    }

    return (
        <Grid container>
            <AccessControl allowedPermissions={[permissions.administration.view]} noAccess={<Unauthorized />}>
                <Grid item xs={12}>
                    <StyledMainContentCard theme={theme} elevation={0}>
                        <CustomCardHeader
                            title={`${routes.dashboardAlerts.pathTitle}`}
                            subheader='Manage alerts displayed on dashboards from the following table.'
                        />
                        {!lookerFolders || !dashboardAlerts ? (
                            <Grid container><div style={{ margin: 'auto', paddingTop: theme.spacing(2) }}><CircularProgress /></div></Grid>
                        ) : (
                            <>
                                <CardContent>
                                    <Typography variant='body1'>
                                        Enabling a Global Alert will disable all current active dashboard alerts.
                                    </Typography>
                                    <Grid item xs={12} sm={12} lg={8} xl={6}>
                                        <table className={tableStyles.table} id='alerts-table'><tbody>
                                            <AlertRow id={'global'} folderName={'Global Alert'} disableSwitch={false}
                                                      state={dashboardAlerts && ('global' in dashboardAlerts) ? 'update' : 'create'}
                                                      active={dashboardAlerts && ('global' in dashboardAlerts) ? dashboardAlerts['global'].active : false}
                                                      alertText={dashboardAlerts && ('global' in dashboardAlerts) ? dashboardAlerts['global'].alertText : ''}
                                            />
                                        </tbody></table>
                                    </Grid>
                                </CardContent>
                                <CardContent style={{overflow: "visible"}}>
                                    <Grid item xs={12} sm={12} lg={8} xl={6}>
                                        <table className={tableStyles.table} id='alerts-table' style={{ position: "sticky", top: "0px", zIndex: 1}}>
                                            <thead>
                                            <tr>
                                                <th className={`${tableStyles.tableCell} ${tableStyles.tableCellHead}`} style={{ width: '15em' }}>Dashboard Folder</th>
                                                <th className={`${tableStyles.tableCell} ${tableStyles.tableCellHead}`} style={{ width: '100px' }}>Off / On</th>
                                                <th className={`${tableStyles.tableCell} ${tableStyles.tableCellHead}`}>Alert Text</th>
                                            </tr>
                                            <tr>
                                                <td className={tableStyles.tableCell}>
                                                    <Input
                                                        id="standard-adornment-amount"
                                                        value={searchString}
                                                        onChange={updateSearchString}
                                                        startAdornment={<InputAdornment position="start"><FilterListIcon /></InputAdornment>}
                                                    />
                                                </td>
                                                <td className={tableStyles.tableCell}></td>
                                                <td className={tableStyles.tableCell}></td>
                                            </tr>
                                            </thead>
                                        </table>
                                        <div>
                                            <table className={`${tableStyles.table}`}>
                                                <tbody>
                                                {matchSorter(lookerFolders, searchString, { keys: ['name'] })
                                                    .map((folder: LookerFolder | null) => {
                                                        const id: string | null = folder?.lookerFolderId || null;
                                                        let status = false;
                                                        let alertText = '';
                                                        let state: 'create' | 'update' = 'create';
                                                        if (dashboardAlerts && id && (id in dashboardAlerts)) {
                                                            status = dashboardAlerts[id].active;
                                                            alertText = dashboardAlerts[id].alertText;
                                                            state = 'update';
                                                        }
                                                        return (
                                                            <AlertRow state={state} key={id} id={id ?? '-1'} folderName={folder?.name || ''} active={status} alertText={alertText}
                                                                      disableSwitch={dashboardAlerts && ('global' in dashboardAlerts) ? dashboardAlerts['global'].active : false}/>
                                                        )
                                                })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Grid>
                                </CardContent>
                            </>
                        )}
                    </StyledMainContentCard>
                </Grid>
            </AccessControl>
        </Grid>
    );
}
