import React, {JSX} from 'react';
import {Typography} from '@mui/material';

interface Props {
    value: string;
}

export const TemporaryPassword = ({ value }: Props): JSX.Element => {

    return (
        <>
            <Typography gutterBottom variant='body2'>
                Temporary Password
            </Typography>
            <Typography>
                {value}
            </Typography>
        </>
    );
}
