import React, {ReactElement, useEffect, useRef, useState} from "react";
import {CircularProgress} from "@mui/material";
import {generateClient} from "aws-amplify/api";
import {Queries, Types} from "../bravo-api-service";


function StorageLinkEmbed(): ReactElement | null {
    const [embedUrl, setEmbedUrl] = useState<string|null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const iframe = useRef<HTMLIFrameElement>(null);

    const handleError =  () => {
        console.error("Error while loading StorageLink");
    }

    useEffect(() => {
        const client = generateClient();
        const fetchEmbedUrl = async () => {
            try {
                const response = await client.graphql({query: Queries.getStorageLinkEmbedUrl}) as {
                    data: Types.GetStorageLinkEmbedUrlQuery;
                };

                if(response.data && response.data.getStorageLinkEmbedUrl) {
                    setEmbedUrl(response.data.getStorageLinkEmbedUrl);
                }
            } catch(error: any) {
                console.error('Error occurred while generating StorageLink embed url: ', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchEmbedUrl().then();
    }, []);

    return <div style={{height: '100%', display: "flex", flexDirection: "column"}}>
        {isLoading && <div style={{margin: 'auto'}}><CircularProgress/></div>}
        {!isLoading && !embedUrl && <div style={{margin: 'auto'}}>There was an error while embedding StorageLink. Please contact your admin.</div>}
        {!isLoading && embedUrl &&
          <iframe title={'StorageLink'}
                  referrerPolicy={'origin-when-cross-origin'}
              src={embedUrl}
              ref={iframe}
              style={{border: "none", width: '100%', flexGrow: 1}}
              onError={handleError}
              seamless
      />
    }
    </div>;


}

export default StorageLinkEmbed;
