import React, {useEffect, useState, JSX} from 'react';
import {CardContent, CircularProgress, FormControl, Grid, IconButton, SelectChangeEvent, Theme} from '@mui/material';
import {useHistory, useParams} from 'react-router-dom';
import {StyledMainContentCard} from '../../../Common/StyledComponents';
import {CustomCardHeader} from '../../../Common/CustomCardHeader';
import {KeyboardBackspace as KeyboardBackspaceIcon} from '@mui/icons-material';
import {generateClient} from 'aws-amplify/api';
import {makeStyles} from '@mui/styles';
import {useTheme} from '@mui/material'
import {useFormStyles} from '../../../Common/MaterialUIStyles';
import {FirstName} from './FirstName';
import {LastName} from './LastName';
import {StorageLinkEmbedUsername} from './StorageLinkEmbedUsername';
import {Email} from './Email';
import {Company} from './Company';
import {EditCrispGroups} from './CrispGroups';
import {EditRoles} from './Roles';
import {TemporaryPassword} from './TemporaryPassword';
import {Notes} from './Notes';
import {EditStatus} from './Status';
import {Mutations, Queries, Types} from '../../../../bravo-api-service';
import {toast} from 'react-toastify';
import {CrispGroup} from '../../types';
import permissions from '../../../RoleBasedAccessControl/permissions';
import Unauthorized from '../../../RoleBasedAccessControl/Unauthorized';
import AccessControl from '../../../RoleBasedAccessControl';
import {listAllFromDynamo} from "../../../../utils";
import {useCrispGroups} from "../../../../api/useCrispGroups";

interface RouteParams {
    userId: string
}

export const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
            padding: `${theme.spacing(1)}px 0px`,
        },
    }),
);

export const EditUser = (): JSX.Element => {
    const theme = useTheme();
    const history = useHistory();
    const formStyles = useFormStyles();
    const editUserClasses = useStyles();
    let { userId } = useParams<RouteParams>();

    const [user, setUser] = useState<any>({});
    const [isLoadingUser, setIsLoadingUser] = useState<boolean>(true);
    const [selectedCrispGroup, setSelectedCrispGroup] = useState<any>([]);
    const [isLoadingCrispGroups, crispGroups] = useCrispGroups();
    const client = generateClient();

    useEffect(() => {
        const fetchUser = async (userId: string) => {
            const response = await client.graphql({query: Queries.getUser, variables: { id: userId }}) as {
                data: Types.GetUserQuery;
            };
            setUser(response.data.getUser);
            const groupItems = await listAllFromDynamo({query: Queries.listGroupUsers, variables: { filter: {userId: {eq: userId}}}}, 'listGroupUsers');
            setSelectedCrispGroup(groupItems.map((item:{crispGroup: any}) => item?.crispGroup));
        };
        fetchUser(userId).then(()=> setIsLoadingUser(false));
    }, [client, userId]);

    const updateUser = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUser({
            ...user,
            [event.target.name]: event.target.value
        });
    };

    const updateRole = (event: SelectChangeEvent) => {
        setUser({
            ...user,
            role: event.target.value as string
        });
    };

    const updateStatus = (value: string) => {
        setUser({
            ...user,
            status: value
        });
    };

    const save = async (key: string) => {
        try {
            // Update this user.
            client.graphql({query: Mutations.updateUser, variables: {
                input: { id: user.id, firstName: user.firstName, lastName: user.lastName, email: user.email,
                    company: user.company, role: user.role, status: user.status, notes: user.notes,
                    storageLinkUsername: user.storageLinkUsername
                }
            }});
            toast.success(`${key} updated.`)
        } catch(error: any) {
            let errorMsg = error.errors.map((e: { errorType: string, message: string }) => `${e.errorType}: ${e.message}`).join('. ');
            toast.error(errorMsg);
            console.error('Error updating a user: ', error);
        }
    }

    const saveCrispGroups = async () => {
        try {
            // Delete all crisp groups associated with the user.
            await Promise.all(user?.crispGroups?.items?.map(async (group: CrispGroup) => {
                await client.graphql({query: Mutations.deleteGroupUser, variables: {input: {id: group.id}}});
            }));
            // Add selected crisp groups to the user.
            await Promise.all(selectedCrispGroup.map(async (group: CrispGroup) => {
                await client.graphql({query: Mutations.createGroupUser, variables: {input: {groupId: group.name, userId}}});
            }));
            toast.success(`Crisp groups updated.`);
        } catch(error: any) {
            let errorMsg = error.errors.map((e: { errorType: string, message: string }) => `${e.errorType}: ${e.message}`).join('. ');
            toast.error(errorMsg);
            console.error('Error while updating user: ', error);
        }
    }

    if (isLoadingUser) {
        return (<Grid container><div style={{ margin: 'auto', paddingTop: theme.spacing(2) }}><CircularProgress /></div></Grid>);
    }

    return (
        <Grid container>
            <AccessControl allowedPermissions={[permissions.administration.edit]} noAccess={<Unauthorized />}>
                <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
                <StyledMainContentCard theme={theme} elevation={0}>
                    <CustomCardHeader
                        avatar={
                            <IconButton aria-label='Go Back' onClick={() => history.goBack()}>
                                <KeyboardBackspaceIcon />
                            </IconButton>
                        }
                        titleTypographyProps={{ variant: 'h5' }}
                        subheaderTypographyProps={{ variant: 'body1' }}
                        title={'Edit User'}
                        subheader='Edit any field and select update to modify the user.'
                    />
                    <CardContent className={formStyles.formMargin}>
                        <FormControl className={`${formStyles.formControl} ${editUserClasses.root}`}>
                            <FirstName value={user ? user.firstName : ''} update={updateUser} save={save}/>
                        </FormControl>
                        <FormControl className={`${formStyles.formControl} ${editUserClasses.root}`}>
                            <LastName value={user ? user.lastName : ''} update={updateUser} save={save} />
                        </FormControl>
                        <FormControl className={`${formStyles.formControl} ${editUserClasses.root}`}>
                            <Email value={user ? user.email : ''} update={updateUser} save={save} />
                        </FormControl>
                        <FormControl className={`${formStyles.formControl} ${editUserClasses.root}`}>
                            <Company value={user ? user.company : ''} update={updateUser} save={save} />
                        </FormControl>
                        <FormControl className={`${formStyles.formControl} ${editUserClasses.root}`}>
                            <StorageLinkEmbedUsername value={user ? user.storageLinkUsername : ''} update={updateUser} save={save} />
                        </FormControl>
                        <FormControl className={`${formStyles.formControl} ${editUserClasses.root}`}>
                            <EditCrispGroups options={crispGroups} selectedCrispGroups={selectedCrispGroup}
                                             updateValue={setSelectedCrispGroup} saveValue={saveCrispGroups}
                                             isLoading={isLoadingCrispGroups} />
                        </FormControl>
                        <FormControl className={`${formStyles.formControl} ${editUserClasses.root}`} variant='outlined' size='small'>
                            <EditRoles value={user ? user.role : ''} update={updateRole} save={save} />
                        </FormControl>
                        <FormControl className={`${formStyles.formControl} ${editUserClasses.root}`}>
                            <TemporaryPassword value={user ? user.temporaryPassword : ''} />
                        </FormControl>
                        <FormControl className={`${formStyles.formControl} ${editUserClasses.root}`} variant='outlined' size='small'>
                            <EditStatus value={user ? user.status : ''} update={updateStatus} save={save} />
                        </FormControl>
                        <FormControl className={`${formStyles.formControl} ${editUserClasses.root}`}>
                            <Notes value={user ? user.notes : ''} update={updateUser} save={save} />
                        </FormControl>
                    </CardContent>
                </StyledMainContentCard>
            </Grid>
            </AccessControl>
        </Grid>
    );
}
