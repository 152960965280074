import React, {useState, JSX} from 'react';
import {NavLink} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {
    Dashboard as DashboardIcon,
    Description as DescriptionIcon,
    ExpandLess,
    ExpandMore,
    Storage as StorageIcon,
    ExitToApp as ExitToAppIcon,
    Menu as MenuIcon,
    Security as SecurityIcon
} from '@mui/icons-material';
import {
    Collapse,
    IconButton,
    List,
    ListItem, ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Menu,
    Theme,
    Typography
} from '@mui/material';
import {makeStyles, withStyles} from '@mui/styles';
import {GlobalBravoState} from '../../../reducers';
import routes from '../../../routes';
import permissions from "../../RoleBasedAccessControl/permissions";
import AccessControl from "../../RoleBasedAccessControl";

export const StyledMuiMenu = withStyles((theme) => ({
    paper: {
        border: `1px solid ${theme.palette.secondary.main}`,
        backgroundColor: '#fff',
    },
}))((props: any) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    })
)

export const ResponsiveNav = (props:{ signOut: () => void; }): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const closeMenu = () => setAnchorEl(null);

    // Control Documentation
    const [openDoc, setOpenDoc] = React.useState(false);
    const handleClickDoc = () => setOpenDoc(!openDoc);

    // Control Administration
    const [openAdmin, setOpenAdmin] = React.useState(false);
    const handleClickAdmin = () => setOpenAdmin(!openAdmin);

    const currentUser = useSelector((state: GlobalBravoState) => state.amplifyAuthReducer?.currentUser);

    const classes = useStyles();

    return (
        <>
            <IconButton
                color='inherit' aria-controls='expand-menu' onClick={openMenu} >
                <MenuIcon color='primary' />
            </IconButton>
            <StyledMuiMenu
                id='expand-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
            >
                <List
                    component='nav'
                    aria-labelledby='nested-list-subheader'
                    subheader={
                        <ListSubheader component='div' id='nested-list-subheader'>
                            <Typography variant='body1'>Hello, {currentUser?.username}</Typography>
                        </ListSubheader>
                    }
                    className={classes.root}
                >
                    <ListItemButton onClick={props.signOut}>
                        <ListItemIcon>
                            <ExitToAppIcon fontSize='small'/>
                        </ListItemIcon>
                        <ListItemText primary={'Sign Out'} />
                    </ListItemButton>
                    <ListItem component={NavLink} to={routes.landing.path}>
                        <ListItemIcon>
                            <DashboardIcon fontSize='small'/>
                        </ListItemIcon>
                        <ListItemText primary={routes.landing.menuTitle} />
                    </ListItem>
                    {currentUser?.storageLinkEmbedName !== null &&
                      <ListItem component={NavLink} to={routes.storageLink.path}>
                        <ListItemIcon>
                          <StorageIcon fontSize='small'/>
                        </ListItemIcon>
                        <ListItemText primary={routes.storageLink.menuTitle}/>
                      </ListItem>
                    }
                    <AccessControl allowedPermissions={[permissions.administration.view]}>
                        <ListItemButton onClick={handleClickDoc}>
                            <ListItemIcon>
                                <DescriptionIcon fontSize='small'/>
                            </ListItemIcon>
                            <ListItemText primary={routes.docs.menuTitle} />
                            {openDoc ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openDoc} timeout='auto' unmountOnExit>
                            <List component='div' disablePadding>
                                <ListItem component={NavLink} to={`${routes.docs.path}/setting-up-access-control-for-a-client`} className={classes.nested}>
                                    <ListItemText primary='Setting up Access Control for a Client' />
                                </ListItem>
                                <ListItem component={NavLink} to={`${routes.docs.path}/creating-custom-themes`} className={classes.nested}>
                                    <ListItemText primary='Creating Custom Themes' />
                                </ListItem>
                                <ListItem component={NavLink} to={`${routes.docs.path}/delete-users`}
                                          className={classes.nested}>
                                    <ListItemText primary='Deleting Users'/>
                                </ListItem>
                                <ListItem component={NavLink} to={`${routes.docs.path}/managing-subdomains`} className={classes.nested}>
                                    <ListItemText primary='Managing Subdomains' />
                                </ListItem>
                                <ListItem component={NavLink} to={`${routes.docs.path}/google-doc-integration`} className={classes.nested}>
                                    <ListItemText primary='Google Doc Integration' />
                                </ListItem>
                            </List>
                        </Collapse>
                    </AccessControl>

                    <AccessControl allowedPermissions={[permissions.administration.view]}>
                        <ListItemButton onClick={handleClickAdmin}>
                            <ListItemIcon>
                                <SecurityIcon fontSize='small'/>
                            </ListItemIcon>
                            <ListItemText primary={routes.admin.menuTitle} />
                            {openAdmin ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openAdmin} timeout='auto' unmountOnExit>
                            <List component='div' disablePadding>
                                <ListItem component={NavLink} to={`${routes.admin.path}${routes.users.path}`} className={classes.nested}>
                                    <ListItemText primary={routes.users.menuTitle} />
                                </ListItem>
                                <ListItem component={NavLink} to={`${routes.admin.path}${routes.groups.path}`} className={classes.nested}>
                                    <ListItemText primary={routes.groups.menuTitle} />
                                </ListItem>
                                <ListItem component={NavLink} to={`${routes.admin.path}${routes.dashboardAlerts.path}`} className={classes.nested}>
                                    <ListItemText primary={routes.dashboardAlerts.menuTitle} />
                                </ListItem>
                            </List>
                        </Collapse>
                    </AccessControl>

                </List>
            </StyledMuiMenu>
        </>
    );
}
