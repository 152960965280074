import React, {JSX} from 'react';
import {useTheme} from '@mui/material/styles';
import {AppBar, Grid, Toolbar} from '@mui/material';
import {StyledSecondaryNavLink} from '../../Common/StyledComponents';

type MenuItem = {
    name?: string,
    route?: string,
    component?: any
}

interface Props {
    menu: MenuItem[]
}

export const SecondaryTopbar = ({ menu }: Props): JSX.Element => {
    const theme = useTheme();
    return (
            <AppBar position='relative' color='primary' elevation={0}>
                <Toolbar variant='dense'>
                    <Grid container style={{justifyContent: "flex-start", alignItems: "center"}} spacing={2}>
                            {menu.map((item, idx) => (
                                <Grid item key={idx}>
                                    {(item.name !== undefined && item.route !== undefined) &&
                                        <StyledSecondaryNavLink theme={theme} to={item.route}>
                                            {item.name}
                                        </StyledSecondaryNavLink>
                                    }
                                    {item.component !== undefined &&
                                        item.component
                                    }
                                </Grid>
                            ))}
                    </Grid>
                </Toolbar>
            </AppBar>
        );
};
