import React, {useState, JSX} from 'react';
import {Autocomplete, CircularProgress, IconButton, TextField, Typography} from '@mui/material';
import CreateSharpIcon from '@mui/icons-material/CreateSharp';
import {useAutocompleteStyles, useFormStyles} from '../../../Common/MaterialUIStyles';
import {CrispGroup} from '../../types';

interface Props {
    selectedCrispGroups: CrispGroup[];
    options: CrispGroup[];
    saveValue: () => void;
    updateValue: (value: CrispGroup[]) => void;
    isLoading?: boolean;
}

export const EditCrispGroups = ({ options, selectedCrispGroups, updateValue, saveValue, isLoading }: Props): JSX.Element => {

    const formClasses = useFormStyles();
    const autoCompleteStyles = useAutocompleteStyles();
    const [isEditing, setEditing] = useState(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

    const submit = async () => {
        setIsLoadingSubmit(true);
        await saveValue();
        setIsLoadingSubmit(false);
        setEditing(false);
    };

    const onBlur = () => {
        submit();
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            submit();
        }
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: CrispGroup[]) => {
        updateValue(newValue);
    };

    return (
        <>
            <Typography gutterBottom variant='body2'>
                Crisp Groups
            </Typography>
            {isEditing ? (
                <div className={`${formClasses.formControl} ${autoCompleteStyles.editRoot}`}>
                    <Autocomplete
                        style={{ width: '90%' }}
                        multiple
                        loading={isLoading === undefined ? true : isLoading}
                        id='crisp-groups'
                        options={options}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, group) => option.name === group.name}
                        filterSelectedOptions
                        size='small'
                        value={selectedCrispGroups}
                        onChange={(event, newValue) => handleChange(event, newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                autoFocus
                                onBlur={onBlur}
                                onKeyDown={handleKeyDown}
                                variant='outlined'
                            />
                        )}
                    />
                    {isLoadingSubmit &&
                        <div className={autoCompleteStyles.iconMarginLeft} >
                            <CircularProgress color='secondary' size={24} />
                        </div>
                    }
                </div>
            ) : (
                <div className={autoCompleteStyles.editRoot}>
                    <Typography>
                        {selectedCrispGroups !== undefined ? selectedCrispGroups.map(group => group.name).join(', ') : ''}
                    </Typography>
                    <IconButton className={autoCompleteStyles.iconMarginLeft} aria-label='Edit' size='small' onClick={() => setEditing(true)}>
                        <CreateSharpIcon fontSize='small' color='primary' />
                    </IconButton>
                </div>
            )}
        </>
    );
}
