import {Hub} from "aws-amplify/utils";
import {
    DashboardEvent,
    DashboardTileDownloadEvent,
    DashboardTileExploreEvent,
    DrillMenuEvent,
    DrillModalExploreEvent,
    ExploreEvent,
    PageChangedEvent
} from "@looker/embed-sdk";
import {HubCapsule, HubPayload} from "@aws-amplify/core";
import analyticsWrapper from "./analyticsWrapper";
import {useEffect} from "react";
import {EventDataMap} from "@aws-amplify/core/src/Hub/types/HubTypes";

const useListener = (): void | any => {

    useEffect(() => {
        const lookerEventMap: Record<string, (event: any) => void> = {
            "dashboard:loaded": (event: DashboardEvent) => analyticsWrapper({
                category: 'Dashboard',
                action: 'View',
                label: {
                    "Dashboard Id": event.dashboard.id
                }
            }),
            "dashboard:download": (event: DashboardEvent) => analyticsWrapper({
                category: 'Dashboard',
                action: 'Download',
                label: {
                    "Dashboard Id": event.dashboard.id
                }
            }),
            "dashboard:tile:download": (event: DashboardTileDownloadEvent) => analyticsWrapper({
                category: 'Tile',
                action: 'Download',
                label: {
                    "Dashboard Id": event.dashboard.id,
                    "Tile Title": event.tile.title
                }
            }),
            "dashboard:tile:explore": (event: DashboardTileExploreEvent) => analyticsWrapper({
                category: 'Tile',
                action: 'Explore',
                label: {
                    "Dashboard Id": event.dashboard.id,
                    "Tile Title": event.tile.title
                }
            }),
            "dashboard:tile:view": (event: DashboardTileExploreEvent) => analyticsWrapper({
                category: 'Tile',
                action: 'View',
                label: {
                    "Dashboard Id": event.dashboard.id,
                    "Tile Title": event.tile.title
                }
            }),
            "dashboard:filters:changed": (event: DashboardEvent) => analyticsWrapper({
                category: 'Dashboard',
                action: 'Filters Changed',
                label: {
                    "Dashboard Id": event.dashboard.id,
                    "Filters": JSON.stringify(event.dashboard.dashboard_filters)
                }
            }),
            "drillmenu:click": (event: DrillMenuEvent) => analyticsWrapper({
                category: 'Drill Menu',
                action: 'Selection',
                label: {
                    "Label": event.label
                }
            }),
            "drillmodal:explore": (event: DrillModalExploreEvent) => analyticsWrapper({
                category: 'Drill Menu',
                action: 'Selection',
                label: {
                    "Label": event.label
                }
            }),
            "explore:state:changed": (event: ExploreEvent) => analyticsWrapper({
                category: 'Explore',
                action: 'Change',
                label: {
                    "URL": event.explore.url
                }
            }),
            "page:changed": (event: PageChangedEvent) => analyticsWrapper({
                category: 'Page',
                action: 'Change',
                label: {
                    "URL": event.page.url,
                }
            })
        }

        const hubCapsuleCallback = (capsule: HubCapsule<string, EventDataMap>) => {
            const payload: HubPayload = capsule.payload;
            if (lookerEventMap[payload.event]) {
                lookerEventMap[payload.event](payload.data);
            }
        }

        const HubListener = () => {
            return Hub.listen('bravo_analytics', hubCapsuleCallback)
        }

        const hubAnalyticsListenerCancel = HubListener();

        return () => {
            //Clean up
            if(hubAnalyticsListenerCancel !== null){
                hubAnalyticsListenerCancel();
            }
        }
    }, [])

}

export default useListener;
