import {record} from 'aws-amplify/analytics';
import {AwsAnalyticsEvent} from "../declarations/awsAnalyticsEvent";
import {AnalyticsEvent} from "../declarations/analyticsEvent";


const AnalyticsRecorder = (event_in: AnalyticsEvent): void => {
    const event: AwsAnalyticsEvent = {
        name: event_in.category?.concat(' ', event_in.action),
        ...(event_in.label && {attribute: event_in.label}),
        ...(event_in.nonInteraction && {nonInteraction: event_in.nonInteraction}),
        ...(event_in.value && {value: event_in.value})
    }

    // Trim event attributes that exceed 200 characters
    if(event && event.attributes) {
        Object.keys(event.attributes).forEach(key => {
            if (event.attributes && event.attributes[key] && typeof event.attributes[key] === 'string')
                event.attributes[key] = event.attributes[key]?.substr(0, 200);
        })
    }
    record({
        name: event.name,
        attributes: event.attributes
    });
};

export default AnalyticsRecorder;
