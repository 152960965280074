import React, {useState, JSX} from 'react';
import {IconButton, MenuItem, Select, SelectChangeEvent, Typography} from '@mui/material';
import CreateSharpIcon from '@mui/icons-material/CreateSharp';
import {useAutocompleteStyles} from '../../../Common/MaterialUIStyles';
import roles, {Roles} from '../../../RoleBasedAccessControl/roles';

interface Props {
    value: string;
    update: (event: SelectChangeEvent) => void;
    save: (key: string) => void;
}

export const EditRoles = ({ value, update, save }: Props): JSX.Element => {

    const [isEditing, setEditing] = useState(false);
    const autoCompleteStyles = useAutocompleteStyles();

    const submit = () => {
        setEditing(false);
        save('Role')
    };

    const onBlur = () => {
        submit();
    };

    const updateRole = (event: SelectChangeEvent) => {
        update(event);
    }

    return (
        <>
            <Typography gutterBottom variant='body2'>
                Role
            </Typography>

            {isEditing ? (
                <>
                    <Select
                        id='role-select'
                        value={value}
                        onChange={updateRole}
                        onBlur={onBlur}
                        variant={'standard'}
                    >
                        {(Object.keys(roles)).map(role =>
                            <MenuItem key={roles[role as keyof Roles].name} value={roles[role as keyof Roles].name}>{roles[role as keyof Roles].displayName}</MenuItem>
                        )}
                    </Select>
                </>
            ) : (
                <Typography onClick={() => setEditing(true)}>
                    {value ? roles[value as keyof Roles].displayName : ''}
                    <IconButton aria-label='Edit' size='small' onClick={() => setEditing(true)} className={autoCompleteStyles.iconMarginLeft}>
                        <CreateSharpIcon fontSize='small' color='primary' />
                    </IconButton>
                </Typography>
            )}
        </>
    );
}
