import React, {JSX} from 'react';
import {makeStyles} from '@mui/styles';
import {CardHeader} from '@mui/material';

const useStyles = makeStyles({
    action: {
        alignSelf: 'auto',
        marginTop: '0px',
        marginRight: '0px'
    }
});

interface Props {
    titleTypographyProps?: object;
    subheaderTypographyProps?: object;
    avatar?: React.ReactNode;
    subheader?: string;
    action?: React.ReactNode;
    title: string;
    className?: string;
}

export const CustomCardHeader = ({ titleTypographyProps, subheaderTypographyProps, avatar, subheader, action, title, className }: Props): JSX.Element => {
    const classes = useStyles();
    return (
        <CardHeader
            titleTypographyProps={titleTypographyProps}
            subheaderTypographyProps={subheaderTypographyProps}
            avatar={avatar}
            action={action}
            title={title}
            subheader={subheader}
            classes={{action: classes.action}}
            className={className}
        />
    );
}
