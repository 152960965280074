import React, {Suspense, JSX} from 'react';
import {LinearProgress} from "@mui/material";

const LazyDashboard = React.lazy(() => import('./Dashboard'));

function LandingPage(): JSX.Element {
    return (
        <Suspense fallback={<LinearProgress/>}>
            <LazyDashboard/>
        </Suspense>
    );
}

export default LandingPage;
