import React, {useState, JSX} from 'react';
import {IconButton, MenuItem, Select, SelectChangeEvent, Typography} from '@mui/material';
import CreateSharpIcon from '@mui/icons-material/CreateSharp';
import {useAutocompleteStyles} from '../../../Common/MaterialUIStyles';
import statuses, {Statuses} from '../status';

interface Props {
    value: string;
    update: (value: string) => void;
    save: (key: string) => void;
}

export const EditStatus = ({ value, update, save }: Props): JSX.Element => {

    const [isEditing, setEditing] = useState(false);
    const autoCompleteStyles = useAutocompleteStyles();

    const submit = () => {
        setEditing(false);
        save('Status');
    };

    const onBlur = () => {
        submit();
    };

    const updateStatus = (event: SelectChangeEvent) => {
        update(event.target.value as string);
    };

    return (
        <>
            <Typography gutterBottom variant='body2'>
                Status
            </Typography>

            {isEditing ? (
                <>
                    <Select
                        id='status-select'
                        value={value}
                        onChange={updateStatus}
                        onBlur={onBlur}
                        variant={'standard'}
                    >
                        {(Object.keys(statuses)).map(status =>
                            <MenuItem key={statuses[status as keyof Statuses].name} value={statuses[status as keyof Statuses].name}>{statuses[status as keyof Statuses].displayName}</MenuItem>
                        )}
                    </Select>
                </>
            ) : (
                <Typography onClick={() => setEditing(true)}>
                    {value ? statuses[value as keyof Statuses].displayName : ''}
                    <IconButton aria-label='Edit' size='small' onClick={() => setEditing(true)} className={autoCompleteStyles.iconMarginLeft}>
                        <CreateSharpIcon fontSize='small' color='primary' />
                    </IconButton>
                </Typography>
            )}
        </>
    );
}
