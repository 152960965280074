import React, {forwardRef, JSX} from 'react';
import {Theme} from '@mui/material';
import {useTheme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles';
import MaterialTable, {MTableBodyRow, MTableCell} from 'material-table';
import {ArrowUpward as ArrowUpwardIcon, FilterList as FilterListIcon,} from '@mui/icons-material';
import {Group, User} from '../../Admin/types';

const useStyles = makeStyles((theme: Theme) =>
    ({
        tableRow: {
            '&:hover': {
                backgroundColor: theme.palette.background.paper
            }
        },
        tableCell: {
            fontWeight: 'inherit'
        }
    }),
);

interface Props {
    data: object[],
    columns: object[],
    onRowClick?: (event?: React.MouseEvent, rowData?: Group | User) => void,
    filtering?: boolean,
    isLoading?: boolean,
    actions?: object[]
}

export const CustomMaterialTable = ({ data, columns, onRowClick, filtering, isLoading, actions }: Props): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles();

    return (
        <MaterialTable
            key={data.length}
            data={data}
            columns={columns}
            onRowClick={onRowClick}
            isLoading={isLoading === undefined ? false: isLoading}
            options={{
                actionsColumnIndex: -1,
                search: false,
                filtering: filtering === undefined ? true : filtering,
                sorting: true,
                pagination: false,
                emptyRowsWhenPaging: false,
                toolbar: false,
                pageSize: data.length,
                headerStyle: {
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.secondary.main,
                    fontWeight: '900',
                },
            }}
            components={{
                Pagination: () => (<></>),
                Row: (props: any) => (<MTableBodyRow {...props} className={classes.tableRow}/>),
                Cell: (props: any) => (<MTableCell {...props} className={classes.tableCell}/>)
            }}
            icons={{
                Filter: forwardRef((props, ignored) => <FilterListIcon {...props} />),
                SortArrow: forwardRef((props, ignored) => <ArrowUpwardIcon {...props} />),
            }}
            style={{
                borderRadius: 0,
                boxShadow: 'none',
                backgroundColor: `${theme.palette.background.default}`
            }}
            actions={actions}
            localization={{
                header: {
                    actions: ''
                }
            }}
        />
    );
};
