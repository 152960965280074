import React, {JSX} from 'react';
import {Autocomplete, TextField} from '@mui/material';
import {CrispGroup} from '../../types';

interface Props {
    options: CrispGroup[];
    updateValue: (value: string[]) => void;
    isLoading?: boolean;
}

export const CrispGroups = ({ updateValue, options, isLoading }: Props): JSX.Element => {

    const handleChange = (event: React.ChangeEvent<{}>, newValue: CrispGroup[]) => {
        updateValue(newValue.map(value => value.name));
    }

    return (
        <Autocomplete
            multiple
            loading={isLoading === undefined ? true : isLoading}
            id='crisp-groups'
            options={options}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, group) => option.name === group.name}
            filterSelectedOptions
            size='small'
            autoHighlight
            onChange={(event, newValue) => handleChange(event, newValue)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant='outlined'
                    label='Crisp Groups'
                />
            )}
        />
    );
}
