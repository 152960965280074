import React, {lazy, Suspense} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom'
import {LinearProgress} from '@mui/material';
import {importMDX} from 'mdx.macro';

import {SecondaryTopbar} from '../components/Topbar/Secondary';
import {Docs} from './component/Docs';
import routes from '../routes';
import AccessControl from '../components/RoleBasedAccessControl';
import permissions from '../components/RoleBasedAccessControl/permissions';

const ManuallyConfigureAccessControl = lazy(() => importMDX('./mdx/setting-up-access-control-for-a-client.mdx'));
const CreatingCustomThemes = lazy(() => importMDX('./mdx/creating-custom-themes.mdx'));
const ManagingSubdomains = lazy(() => importMDX('./mdx/managing-subdomains-for-crispdata.io.mdx'));
const GoogleDocIntegration = lazy(() => importMDX('./mdx/google-doc-integration.mdx'))
const DeleteUsers = lazy(() => importMDX('./mdx/delete-users.mdx'))
const ManageMfa = lazy(() => importMDX('./mdx/manage-mfa-configuration-for-user.mdx'));

const DocsIndex = () => {
    return (
        <Suspense fallback={<LinearProgress/>}>
            <AccessControl allowedPermissions={[permissions.administration.view]}>
                <SecondaryTopbar menu={[
                    {name: 'Setting up Access Control for a Client', route: `${routes.docs.path}/setting-up-access-control-for-a-client`},
                    {name: 'Creating Custom Themes', route: `${routes.docs.path}/creating-custom-themes`},
                    {name: 'Deleting Users', route: `${routes.docs.path}/delete-users`},
                    {name: 'Managing Subdomains', route: `${routes.docs.path}/managing-subdomains`},
                    {name: 'Google Doc Integration', route: `${routes.docs.path}/google-doc-integration`},
                    {name: 'Manage User MFA', route: `${routes.docs.path}/manage-mfa-configuration-for-user`},
                ]} />
            </AccessControl>
            <Switch>
                <Route path={`${routes.docs.path}/setting-up-access-control-for-a-client`} exact>
                    <Docs>
                        <ManuallyConfigureAccessControl/>
                    </Docs>
                </Route>
                <Route path={`${routes.docs.path}/creating-custom-themes`} exact>
                    <Docs>
                        <CreatingCustomThemes />
                    </Docs>
                </Route>
                <Route path={`${routes.docs.path}/delete-users`} exact>
                    <Docs>
                        <DeleteUsers/>
                    </Docs>
                </Route>
                <Route path={`${routes.docs.path}/managing-subdomains`} exact>
                    <Docs>
                        <ManagingSubdomains />
                    </Docs>
                </Route>
                <Route path={`${routes.docs.path}/google-doc-integration`} exact>
                    <Docs>
                        <GoogleDocIntegration />
                    </Docs>
                </Route>
                <Route path={`${routes.docs.path}/google-doc-integration`} exact>
                    <Docs>
                        <GoogleDocIntegration />
                    </Docs>
                </Route>
                <Route path={`${routes.docs.path}/manage-mfa-configuration-for-user`} exact>
                    <Docs>
                        <ManageMfa/>
                    </Docs>
                </Route>
                <Redirect to={`${routes.docs.path}/setting-up-access-control-for-a-client`}/>
            </Switch>
        </Suspense>
    );
}
export default DocsIndex;
