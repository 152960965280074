import {useEffect, useState} from 'react';
import {AuthUser} from '@aws-amplify/auth';
import {getCurrentUser, signOut, fetchUserAttributes, fetchAuthSession, updateUserAttribute} from 'aws-amplify/auth';
import {HubCapsule, HubPayload} from '@aws-amplify/core';
import {Hub} from 'aws-amplify/utils';
import {fetchError, fetchUserDataInit, resetUser, setCurrentUser, setDashboard} from '../actions';
import {useDispatch} from 'react-redux';
import {CurrentUserInfo} from '../bravo-api-service/customTypes';
import {clearLookerSessionCookie} from "./clearLookerSessionCookie";
import {Queries, Types} from "../bravo-api-service";
import { generateClient } from 'aws-amplify/api';
import {EventDataMap, StopListenerCallback} from "@aws-amplify/core/src/Hub/types/HubTypes";

const ATTR_LAST_DASHBOARD = "custom:recent_dashboard";
const DEFAULT_DASHBOARD_ID = "411"

const useAmplifyAuth = (): void | any => {


    const dispatch = useDispatch();
    const [triggerFetch, setTriggerFetch] = useState(false)

    useEffect(() => {
        let isMounted = true
        const client = generateClient();

        const fetchUserData = async () => {
            if (isMounted) {
                dispatch(fetchUserDataInit())
            }
            try {
                if (isMounted) {
                    const cogUser: AuthUser = await getCurrentUser();
                    const attributes = await fetchUserAttributes();
                    const authSession = await fetchAuthSession();
                    const response = await client.graphql({query: Queries.getUser, variables: { id: cogUser.userId }}) as {
                        data: Types.GetUserQuery;
                    };
                    const user: CurrentUserInfo = {
                        id: cogUser.userId,
                        username: cogUser.username,
                        cognitoGroups: authSession.tokens?.accessToken?.payload["cognito:groups"] as string[] || [],
                        role: authSession.tokens?.idToken?.payload?.crisp_role as string || "",
                        attributes: attributes,
                        storageLinkEmbedName: response.data.getUser?.storageLinkUsername || null
                    }
                    if (user.attributes[ATTR_LAST_DASHBOARD]) {
                        dispatch(setDashboard({
                            dashboardId: String(user.attributes[ATTR_LAST_DASHBOARD]) || DEFAULT_DASHBOARD_ID,
                            dashboardFolderId: null,
                            isLoading: true
                        }));
                    } else {
                        dispatch(setDashboard({
                            dashboardId: DEFAULT_DASHBOARD_ID,
                            dashboardFolderId: null,
                            isLoading: true
                        }));
                    }
                    dispatch(setCurrentUser(user))
                }
            } catch(error: any) {
                if (isMounted) {
                    dispatch(fetchError())
                }
            }
        }

        const updateViewedDashboard = (dashboardId: string) => {
            updateUserAttribute({userAttribute: {
                    attributeKey: ATTR_LAST_DASHBOARD, value: `${dashboardId}`
                }}).then(res => {
                    if (!res.isUpdated) {
                        console.error(res)
                    }
                }
            );
        }

        const listenerCallback = (data: HubCapsule<string, EventDataMap>) => {
            const {payload} = data
            onAuthEvent(payload)
        };

        let hubUserListenerCancel: StopListenerCallback, hubAuthListenerCancel: StopListenerCallback;
        const HubListener = () => {
            hubUserListenerCancel = Hub.listen('user', listenerCallback)
            hubAuthListenerCancel = Hub.listen('auth', listenerCallback)
        }

        const onAuthEvent = (payload: HubPayload): any => {
            switch (payload.event) {
                case 'signedIn':
                    setTriggerFetch(true)
                    break
                case 'viewDashboard':
                    if(payload.data  !== null
                        && typeof payload.data === 'object'
                        && "id" in payload.data
                        && typeof payload.data?.id === 'string') {
                        updateViewedDashboard(payload.data.id);
                    }
                    break
                default:
                    return
            }
        }

        HubListener()
        fetchUserData().then();

        return () => {
            if(hubUserListenerCancel !== null){
                hubUserListenerCancel();
            }
            if(hubAuthListenerCancel !== null){
                hubAuthListenerCancel();
            }
            isMounted = false
        }
    }, [triggerFetch, dispatch])

    const handleSignOut = async () => {
        try {
            //First clear looker embed tokens
            await clearLookerSessionCookie();
            //Then sign out of cognito
            await signOut();
            dispatch(resetUser());
            setTriggerFetch(false);
            window.localStorage.removeItem('bravo_google_access_key');
            window.localStorage.removeItem('bravo_google_expires_at');
        } catch(error: any) {
            console.error('Error signing out user ', error)
        }
    }

    return {handleSignOut}
}

export default useAmplifyAuth;
