import {Queries} from "../bravo-api-service";
import {listAllFromDynamo} from "../utils";
import {useEffect, useState} from "react";

let initItemsLocal: any[] = [];

export function useCrispGroups(): [boolean, any[], () => void] {

    const [triggerRefresh, setTriggerRefresh] = useState(true);
    const [itemsLocal, setItemsLocal] = useState(initItemsLocal);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(function loadGroups() {
        const fetchData = async (): Promise<any> => {
            try {
                const graphqlOp = {query: Queries.listCrispGroups};
                const listFieldName = 'listCrispGroups';
                setItemsLocal(await listAllFromDynamo(graphqlOp, listFieldName));
            } catch (error: any) {
                console.error('Error occurred while loading crisp groups: ', error);
                if (error.data.listCrispGroups?.items) {
                    setItemsLocal(error.data.listCrispGroups?.items);
                }
            } finally {
                setIsLoading(false);
            }
        };
        setIsLoading(true);
        fetchData().then();
    }, [triggerRefresh])

    return [isLoading, itemsLocal, () => setTriggerRefresh(!triggerRefresh)]
}
