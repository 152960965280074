import React, {useState, JSX} from 'react';
import {useHistory} from 'react-router-dom';
import {
    CardContent,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import {KeyboardBackspace as KeyboardBackspaceIcon} from '@mui/icons-material';
import {generateClient} from 'aws-amplify/api';
import {useTheme} from '@mui/material/styles';
import {StyledFormButtonsWrapper, StyledMainContentCard, StyledSubmitButton} from '../../../Common/StyledComponents';
import {CustomCardHeader} from '../../../Common/CustomCardHeader';
import roles, {Roles} from '../../../RoleBasedAccessControl/roles';
import {CrispGroups} from './CrispGroups';
import {useButtonProgressStyles, useFormStyles} from '../../../Common/MaterialUIStyles';
import statuses, {Statuses} from '../status';
import {Mutations, Types} from '../../../../bravo-api-service';
import routes from '../../../../routes';
import {toast} from 'react-toastify';
import permissions from '../../../RoleBasedAccessControl/permissions';
import Unauthorized from '../../../RoleBasedAccessControl/Unauthorized';
import AccessControl from '../../../RoleBasedAccessControl';
import {useCrispGroups} from "../../../../api/useCrispGroups";

export const CreateUser = (): JSX.Element => {

    const theme = useTheme();
    const history = useHistory();
    const classes = useFormStyles();
    const buttonLoadingStyle = useButtonProgressStyles();
    const client = generateClient();

    const [form, setState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        role: roles.USER.name,
        status: statuses.ACTIVE.name,
        temporaryPassword: '',
        notes: ''
    });

    const [selectedCrispGroups, setSelectedCrispGroups] = useState<any>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isLoadingCrispGroups, crispGroups] = useCrispGroups();

    const updateForm = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...form,
            [event.target.name]: event.target.value
        });
    };

    const updateRole = (event: SelectChangeEvent) => {
        setState({
            ...form,
            role: event.target.value as string
        });
    };

    const updateStatus = (event: SelectChangeEvent) => {
        setState({
            ...form,
            status: event.target.value as string
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            setIsSubmitting(true);
            // Create a new user.
            const newUser = await client.graphql({query: Mutations.createUser, variables: {input: {...form}}}) as {
                data: Types.CreateUserMutation;
            };
            // Link the user to the selected crisp groups.
            if(newUser.data.createUser
                && newUser.data.createUser.id ) {
                const userId = newUser.data.createUser.id;
                await Promise.all(selectedCrispGroups.map(async (groupId: string) => {
                    await client.graphql({
                        query: Mutations.createGroupUser,
                        variables: {input: {userId, groupId}}
                    });
                }));
            }
            setIsSubmitting(false);
            toast.success(`Created ${newUser.data.createUser?.firstName} ${newUser.data.createUser?.lastName} user.`)
            history.push(`${routes.admin.path}${routes.users.path}`);
        } catch (error: any) {
            let errorMsg = error.errors.map((e: { errorType: string, message: string }) => `${e.message}`).join('. ');
            if (errorMsg.includes('Status Code: 400')) {
                errorMsg = 'This email address has been registered to another user. Pick a new email address.';
            }
            toast.error(errorMsg);
            setIsSubmitting(false);
            console.error('Error creating a user: ', error);
        }

    }

    const onCancel = () => {
        history.push(`${routes.admin.path}${routes.users.path}`);
    }

    return (
        <Grid container>
            <AccessControl allowedPermissions={[permissions.administration.create]} noAccess={<Unauthorized />}>
                <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
                <StyledMainContentCard theme={theme} elevation={0}>
                    <CustomCardHeader
                        avatar={
                            <IconButton aria-label='Go Back' onClick={() => history.goBack()}>
                                <KeyboardBackspaceIcon />
                            </IconButton>
                        }
                        titleTypographyProps={{ variant: 'h5' }}
                        subheaderTypographyProps={{ variant: 'body1' }}
                        title={'Add User'}
                        subheader='Complete the following fields and select create to add a new user to the system.'
                    />
                    <CardContent className={classes.formMargin}>
                        <form autoComplete='off' onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl className={classes.formControl} required>
                                        <TextField variant='outlined' label='First Name' size='small' name='firstName' onChange={updateForm} required />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl className={`${classes.formControl} required`} required>
                                        <TextField variant='outlined' label='Last Name' size='small' name='lastName' onChange={updateForm} required />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} required>
                                        <TextField variant='outlined' label='Email Address' type='email' size='small' name='email' onChange={updateForm}  required />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} required>
                                        <TextField variant='outlined' label='Company' size='small' name='company' onChange={updateForm} required />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} required>
                                        <TextField variant='outlined' label='StorageLink Embed Username' size='small' name='storageLinkUsername' onChange={updateForm} required />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} variant='outlined'>
                                        <CrispGroups options={crispGroups} updateValue={setSelectedCrispGroups} isLoading={isLoadingCrispGroups} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} variant='standard' size='small' required>
                                        <InputLabel id='role-select-label'>Role</InputLabel>
                                        <Select labelId='role-select-label' id='role-select' variant={'standard'} label='Role' name='role' value={form.role} onChange={updateRole}>
                                            {(Object.keys(roles)).map(role =>
                                                <MenuItem key={roles[role as keyof Roles].name} value={roles[role as keyof Roles].name}>{roles[role as keyof Roles].displayName}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} required>
                                        <TextField variant='outlined' label='Temporary Password' size='small' name='temporaryPassword' onChange={updateForm} required />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} variant='outlined' size='small' required>
                                        <InputLabel id='status-select-label'>Status</InputLabel>
                                        <Select labelId='status-select-label' id='status-select' label='Status' name='status' value={form.status} onChange={updateStatus} variant={'standard'}>
                                            {(Object.keys(statuses)).map(status =>
                                                <MenuItem key={statuses[status as keyof Statuses].name} value={statuses[status as keyof Statuses].name}>{statuses[status as keyof Statuses].displayName}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl}>
                                        <TextField label='Notes' multiline rows={4} variant='outlined' name='notes' onChange={updateForm} />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <StyledFormButtonsWrapper>
                                <StyledSubmitButton theme={theme} color='secondary' type='reset' onClick={onCancel}>Cancel</StyledSubmitButton>
                                <div className={buttonLoadingStyle.wrapper}>
                                    <StyledSubmitButton theme={theme} variant='contained' color='secondary' type='submit'
                                                        disabled={form.firstName === '' || form.lastName === '' || form.email === ''
                                                        || form.company === '' || form.temporaryPassword === ''}>
                                        Create</StyledSubmitButton>
                                    {isSubmitting && <CircularProgress size={24} className={buttonLoadingStyle.buttonProgress} />}
                                </div>
                            </StyledFormButtonsWrapper>
                        </form>
                    </CardContent>
                </StyledMainContentCard>
            </Grid>
            </AccessControl>
        </Grid>
    );
}
