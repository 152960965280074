import React, {JSX} from 'react';
import { useSelector } from 'react-redux';
import rules from './rules';
import {GlobalBravoState} from '../../reducers';

export const check = (actions: string[], role: string | undefined) => {

    const permissions = rules[role !== undefined ? role : ''];
    if (!permissions) {
        return false;
    }
    if (actions.length === 0) {
        return true;
    }
    // Check static permissions
    const staticPermissions = permissions.static;
    if (staticPermissions) {
        return actions.some(action => staticPermissions.includes(action));
    }

    return false;
};

type Props = {
    allowedPermissions: string[];
    children: React.ReactNode;
    noAccess?: React.ReactNode;
}

const AccessControl = (props: Props) : JSX.Element => {
    const currentUserRole = useSelector((state: GlobalBravoState) => state.amplifyAuthReducer?.currentUserRole);
    if (check(props.allowedPermissions, currentUserRole?.toUpperCase())) {
        return (<>{props.children}</>)
    } else {
        return (<>{props.noAccess}</>)
    }
};

export default AccessControl;
