import {fetchAuthSession} from 'aws-amplify/auth';
import {get} from 'aws-amplify/api';

export async function clearLookerSessionCookie() {
    await fetchAuthSession()
        .then(session => {
            return {
                headers: {
                    Authorization: `Bearer ${session.tokens?.idToken}`
                },
                withCredentials: true
            };
        }).then(restInit => {
            return get({
                apiName: 'lookerEmbedApi',
                path: "/looker/clear-embed-session",
                options: restInit
            });
        })
}
